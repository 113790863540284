import { FC } from 'react'
import Image from 'next/image'
import styled from 'styled-components'
import { Close } from 'svgIcons'
import Button from '@app.components/Button'
import Modal, { IFModalProps } from '@app.components/Modal/Modal'
import getChatDynamicLink from '@app.feature/activity/activity.detail/module/getChatDynamicLink'
import { DYNAMIC_LINK } from 'app.constants/firebaseUrls'

interface IFChatAppModalProps extends IFModalProps {
  chatRoomId?: string
  isModalOverlapping?: boolean
}

const ChatAppModal: FC<IFChatAppModalProps> = ({
  onClose,
  chatRoomId,
  isModalOverlapping = false,
  ...props
}) => {
  const handleClickButton = () => {
    if (chatRoomId) {
      window.open(getChatDynamicLink(chatRoomId))
    } else {
      location.href = DYNAMIC_LINK.chat
    }
  }

  return (
    <Modal isModalOverlapping={isModalOverlapping} onClose={onClose} {...props}>
      <StyledWrapper>
        <Close className="close-icon" onClick={onClose} />
        <Image
          src="/static/images/chat/chat-app.svg"
          alt="채팅"
          width={200}
          height={135}
        />
        <div className="chat-modal-title">
          <span>링커리어 앱에서</span> 채팅 기능을 이용해보세요!
        </div>
        <div className="chat-modal-desc">
          채팅방에서 기업의 채용 정보, 합격 스펙 등 <br /> 실시간으로 정보를
          공유하세요.
        </div>
        <Button className="app-btn" onClick={handleClickButton}>
          앱으로 이동하기
        </Button>
        <a className="footer" onClick={onClose}>
          다음에 이용할게요.
        </a>
      </StyledWrapper>
    </Modal>
  )
}

export default ChatAppModal

const StyledWrapper = styled.div`
  position: absolute;
  bottom: 20px;
  left: 10px;
  right: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 352px;
  border-radius: 20px;
  background-color: #fff;
  padding: 20px 10px 26px;
  .close-icon {
    width: 20px;
    margin-right: 10px;
    align-self: flex-end;
  }
  .chat-modal-title {
    font-size: 16px;
    font-weight: bold;
    line-height: 1.75;
    letter-spacing: -0.48px;
    margin-top: 5px;
    span {
      color: #01a0ff;
    }
  }
  .chat-modal-desc {
    text-align: center;
    color: #666;
    font-size: 12px;
    line-height: 1.42;
    letter-spacing: -0.36px;
    padding-top: 5px;
  }
  .app-btn {
    width: 100%;
    height: 50px;
    margin: 15px 0;
    font-size: 16px;
  }
  .footer {
    color: #999;
    font-size: 13px;
  }
`
